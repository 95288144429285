<template>
  <div class="schedule">
    <img class="background" src="../../assets/image/background.png" alt srcset />
    <header>
      <img src="../../assets/image/logo.png" alt srcset />
    </header>
    <van-divider :style="dividerStyle">大会议程</van-divider>
    <div class="content">
      <div class="meetingplace">
        <div
          :class="venueIndex==index? 'meetingplace_title active':'meetingplace_title'"
          v-for="(item,index) in venue"
          :key="index"
          @click="getVenueContent(item.id,index)"
        >{{item.name}}</div>
      </div>
      <van-collapse v-model="activeNames" :border="false">
        <van-collapse-item
          :name="item.time"
          :border="false"
          v-for="(item,index) in venueContent"
          :key="index"
        >
          <template #title>
            <div class="dateTitle">
              <!-- <div>{{item.time}}</div> -->
              <div class="dateTitle_left">
                <!-- <div>08:00-10:30</div>
                <div>翰林厅</div>-->
                <!-- <div></div>
                <div>翰林厅</div>-->
                <div>
                  <div>{{item.date}}</div>
                  <div>{{item.court}}</div>
                </div>
              </div>
              <div class="dateTitle_right">{{item.time}}</div>
            </div>
          </template>
          <img :src="item.image" alt />
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="tips">
      <div class="tips_box">
        <div class="tips_img">
          <img src="../../assets/image/headset.png" alt />
        </div>
      </div>
    </div>
    <div class="footer-fill"></div>
    <el-footer :index="1" />
  </div>
</template>

<script>
import footer from "../../components/footer/footer-tempate.vue";
import { getVenue, getVenueContent } from "../../api/index.js";
export default {
  components: {
    "el-footer": footer
  },
  data() {
    return {
      activeNames: [],
      dividerStyle: {
        color: "#FFFFFF",
        borderColor: "#fff",
        padding: "0 25%",
        "font-size": "16px"
      },
      venue: [],
      venueIndex: 0,
      venueContent: [],
    };
  },
  methods: {
    getVenue() {
      getVenue()
        .then(res => {
          this.venue = res.data;
          console.log(this.venue[0].id);
          this.getVenueContent(this.venue[0].id, 0);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getVenueContent(id, index) {
      this.venueIndex = index;
      getVenueContent({ id: id })
        .then(res => {
          // console.log(res)
          this.venueContent = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.getVenue();
  }
};
</script>

<style lang="less" scoped>
.schedule {
  min-height: 100vh;
  // background-image: url("../../assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .background {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    z-index: -1;
  }
  header {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  .box {
    height: 46px;
  }
  .van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar {
    background-color: #f2986e;
  }
  .cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    width: 92%;
    margin: 0 4% 10px 4%;
    background-color: #693853;
    border: 1px solid #fff;
    .title {
      color: #fff;
      font-size: 16px;
    }
  }
  .content {
    width: 90%;
    margin: 0 auto;
    // background-color:transparent;
    background-color: rgb(106, 56, 83, 0.6);
    .meetingplace {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      .meetingplace_title {
        background-color: #440a34;
        border: #fff solid 1px;
        // margin: 0 5px 5px 5px;
        height: 40px;
        width: 22%;
        color: #fff;
        margin-bottom: 10px;
        margin-right: 3%;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        // flex: none;
      }
      .active {
        // background-color: #f0906d;
        color: #f0906d;
      }
      .meetingplace_title:nth-of-type(4n) {
        margin-right: 0;
      }
    }
    .dateTitle {
      display: flex;
      font-size: 12px;
      line-height: 14px;
      .dateTitle_left {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        text-align: center;
      }
      .dateTitle_right {
        flex: none;
        display: flex;
        align-items: center;
        width: 73%;
      }
    }
    /deep/.van-cell {
      background-color: transparent;
      color: #fff;
      margin: 5px 0;
      // height: 100%;
      background-image: url("../../assets/image/schedule2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    /deep/ .van-cell {
      // height: 40px;
      min-height: 1px;
    }
    /deep/.van-collapse-item__title--borderless {
      display: flex;
      align-items: center;
      padding: 8px 0;
    }
    /deep/.van-collapse-item__content {
      background-color: transparent;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      img {
        width: 100%;
        padding: 0;
      }
    }
  }
  .tips {
    width: 90%;
    margin: 20px auto;
    // padding-bottom: 10px;
    .tips_box {
      display: flex;
      justify-content: flex-end;
      height: 10px;
      .tips_img {
        flex: none;
        img {
          width: 258px;
          height: 17px;
        }
      }
    }
  }
}
.footer-fill {
  height: 37px;
}
</style>